import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout'
import PageHead from '../layout/PageHead'
import Section from '../components/Section'
import CardHorizontal from '../components/CardHorizontal'
import PageHeader from '../components/Sections/PageHeader'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.posts.edges.map(({ node }) => node)
    const tag = this.props.pageContext.tag
    const page = {
      title: `SoftKraft - Blog: ${tag}`,
      description: `SoftKraft - Blog: ${tag}`,
      robots: 'noindex, follow'
    }

    return (
      <Layout>
        <PageHeader dark={false} />
        <PageHead {...page} />
        <Section classes="text-large text-center pb-0">
          <h2>
            Blog: <i>{tag}</i>
          </h2>
          <hr />
        </Section>
        <Section classes="blog">
          {posts.map(post => (
            <CardHorizontal key={post.path} {...post}>
              <h2>{post.title}</h2>
              <p>{post.description}</p>
            </CardHorizontal>
          ))}
        </Section>
      </Layout>
    )
  }
}

export default TagRoute

export const query = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    posts: allPostsYaml(limit: 1000, sort: { fields: [date], order: DESC }, filter: { tags: { in: [$tag] } }) {
      totalCount
      edges {
        node {
          path
          title
          description
          thumb
          tag
        }
      }
    }
  }
`
